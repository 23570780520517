import React, { createContext, useState, useContext } from 'react';

// Create a context for the user and product data
const OrderContext = createContext();

export const useOrderContext = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
  const [useUser, setUseUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [product, setProduct] = useState(null);
  const [googleUser, setGoogleUser] = useState(null); // Store Google user data


  return (
    <OrderContext.Provider value={{ googleUser, setGoogleUser, userData, setUserData, product, setProduct, useUser, setUseUser  }}>
      {children}
    </OrderContext.Provider>
  );
};
