import React, { useState, useContext } from 'react';
import { SellerContext } from '../../Accounts/SellerContext';

const CreateCategory = () => {
  const { seller } = useContext(SellerContext);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null); // Add state for image
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    if (!name) {
      setError('Category name is required');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('seller_id', seller?.seller?.id);
    if (image) {
      formData.append('image', image); // Add image to formData if exists
    }

    const response = await fetch('http://39.61.51.195:8004/account/category/', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    setLoading(false);

    if (response.ok) {
      setName('');
      setImage(null); // Reset the image field
      alert('Category created successfully!');
    } else {
      setError(data.message || 'Failed to create category');
    }
  };

  return (
    <div className="bg-black text-white p-5 rounded">
      <h2 className="text-3xl mb-4">Create Category</h2>
      <form onSubmit={handleCreateCategory}>
        <div className="mb-4">
          <label className="block text-left text-sm font-bold mb-2">
            Category Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 text-black rounded"
            placeholder="Enter category name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-left text-sm font-bold mb-2">
            Category Image
          </label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])} // Handle image file selection
            className="w-full p-2 text-white rounded"
            accept="image/*"
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          type="submit"
          className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          disabled={loading}
        >
          {loading ? 'Creating...' : 'Create Category'}
        </button>
      </form>
    </div>
  );
};

export default CreateCategory;
