import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../../Components/Pages/Products/Oders/OrderContext"; // import the context
import { useShoppingCart } from "../../../Context/ShoppingCartContext"; // import the shopping cart context

const ProductDetails = ({ product, onClose }) => {
  const { setProduct, userData } = useOrderContext(); // Access userData from context
  const { addToCart, notification } = useShoppingCart(); // access addToCart and notification from the shopping cart context
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setProduct(product); // save selected product to context

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [product, setProduct]);

  const handleAddToCart = () => {
    if (userData) {
      addToCart(product);
    } else {
      navigate("/userLogin");
    }
  };

  const handleBuyNow = () => {
    if (userData) {
      navigate("/createOrder");
    } else {
      navigate("/userLogin");
    }
  };

  if (!product) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-4xl relative">
        {notification && (
          <div
            className={`absolute top-0 left-0 w-full p-2 text-center rounded-t-md ${
              notification === "Product added successfully!"
                ? "bg-green-500"
                : "bg-red-500"
            } text-white`}
          >
            {notification}
          </div>
        )}

        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold">{product.name}</h2>
          <button onClick={onClose} className="text-red-500 text-xl">
            &times;
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img
              src={
                product.images.length > 0
                  ? product.images[0].image
                  : "placeholder.jpg" // Fallback to a placeholder if the second image doesn't exist
              }
              alt={product.name}
              className="w-full h-64 object-cover mb-4"
            />
            <div className="flex justify-center">
              {product.additionalImages &&
                product.additionalImages.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Additional view ${index + 1}`}
                    className="w-20 h-20 object-cover m-1"
                  />
                ))}
            </div>
          </div>

          <div>
            <p className="text-gray-600 mb-4">{product.description}</p>
            <p className="text-gray-800 font-bold text-lg">
              Price: Rs. {product.price}
            </p>

            <div className="flex items-center my-4">
              <div className="flex">
                {[...Array(product.rating || 0)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-6 h-6 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 15l-5.5 3 2.2-5.4L2 7.6l5.8-.5L10 2l2.2 5.1 5.8.5-4.7 4.6L15.5 18z" />
                  </svg>
                ))}
              </div>
              <span className="ml-2 text-gray-700">
                {product.reviews_count} Ratings |{" "}
                {product.questions_answered_count} Answered Questions
              </span>
            </div>

            <div className="border-t-2 border-b-2 py-4 my-4">
              {/* <p className="text-gray-700"><strong>Product ID:</strong> {product.id}</p>
              <p className="text-gray-700"><strong>Brand:</strong> {product.brand}</p>
              <p className="text-gray-700"><strong>Warranty:</strong> {product.warranty} Months Warranty</p> */}
              <p className="text-gray-700">
                <strong>Delivery:</strong> Cash On Delivery
              </p>
              {/* <p className="text-gray-700"><strong>Returns:</strong> {product.returns}</p> */}
            </div>

            <div className="flex space-x-4 mt-4">
              <button
                onClick={handleBuyNow}
                className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
              >
                Buy Now
              </button>
              <button
                onClick={handleAddToCart}
                className="w-full py-3 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
