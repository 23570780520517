import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SellerContext } from "../Accounts/SellerContext";
import ProductsList from "../Products/ProductsList/ProductsList";
import { HiMiniShoppingBag } from "react-icons/hi2";
import OrderManage from "./OrderManage";
import LoadingPage from "../../../LoadingPage";

const SellerDashboard = () => {
  const { seller, logout } = useContext(SellerContext); // Assuming there's a logout function in the context
  const [selectedSection, setSelectedSection] = useState("profile");
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    if (seller) {
      console.log("Seller Data in Dashboard", seller);
    }
  }, [seller]);

  if (!seller) {
    return <LoadingPage />;
  }

  const handleLogout = () => {
    logout();
    navigate("/SellerLoginPage"); // Use navigate instead of history.push
  };

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4 flex justify-between items-center">
              <span>
                Wellcom back {seller.seller.first_name || "N/A"}{" "}
                {seller.seller.last_name || "N/A"}!
              </span>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
              >
                Logout
              </button>
            </h2>
            {/* line */}
            <div class="text-center pb-5">
              <div class="w-full h-1 bg-blue-500 mx-auto mt-2"></div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seller_id"
                >
                  Name:
                </label>
                <div className="shadow appearance-none border rounded py-2 px-3 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline">
                  <p>
                    {seller.seller.first_name || "N/A"}{" "}
                    {seller.seller.last_name || "N/A"}
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seller_id"
                >
                  Seller ID:
                </label>
                <div className="shadow appearance-none border rounded py-2 px-3 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline">
                  <p>{seller.seller.id || "N/A"}</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seller_id"
                >
                  Email:
                </label>
                <div className="shadow appearance-none border rounded py-2 px-3 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline">
                  <p>{seller.seller.email || "N/A"}</p>
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="seller_id"
                >
                  Phone Number:
                </label>
                <div className="shadow appearance-none border rounded py-2 px-3 bg-gray-200 leading-tight focus:outline-none focus:shadow-outline">
                  <p>{seller.seller.phone_number || "N/A"}</p>
                </div>
              </div>
            </div>
          </div>
        );
      case "orders":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Orders</h2>
            <OrderManage />
          </div>
        );
      case "addProduct":
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Add Products and Create Categories
            </h2>
            <Link
              to={`/add-products`}
              className="inline-block p-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Add Products
            </Link>
            <Link
              to={`/create-category`}
              className="inline-block p-5 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
            >
              Create Category
            </Link>
          </div>
        );
      case "products":
        return (
          <div>
            <ProductsList seller={seller.seller} />
          </div>
        );
      default:
        return <div>Select a section</div>;
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        {/* Sidebar */}
        {/* <aside className="w-full md:w-16 bg-green-500 h-screen shadow-lg">
          <div className="flex flex-col items-center py-4">
            <div className="my-4 pb-4 flex flex-row md:flex-col">
              <HiMiniShoppingBag
                className="text-3xl mb-4 cursor-pointer hover:text-red-600"
                onClick={() => setSelectedSection("profile")}
              />
              <HiMiniShoppingBag
                className="text-3xl mb-4 cursor-pointer hover:text-red-600"
                onClick={() => setSelectedSection("orders")}
              />
              <HiMiniShoppingBag
                className="text-3xl mb-4 cursor-pointer hover:text-red-600"
                onClick={() => setSelectedSection("addProduct")}
              />
              <HiMiniShoppingBag
                className="text-3xl mb-4 cursor-pointer hover:text-red-600"
                onClick={() => setSelectedSection("products")}
              />
            </div>
          </div>
        </aside> */}

        {/* Main Content */}
        <div className="flex-1 p-6">
          {/* Section for Navigation */}
          <section className="bg-gradient-to-r from-blue-400 to-blue-600 text-white p-6 rounded-lg mb-6">
            <div className="flex justify-around">
              <div
                className={`text-center cursor-pointer p-4 rounded-lg ${
                  selectedSection === "profile" ? "bg-white text-blue-600" : ""
                }`}
                onClick={() => setSelectedSection("profile")}
              >
                <span className="block text-xl font-bold">1</span>
                <span>Profile</span>
              </div>
              <div
                className={`text-center cursor-pointer p-4 rounded-lg ${
                  selectedSection === "orders" ? "bg-white text-blue-600" : ""
                }`}
                onClick={() => setSelectedSection("orders")}
              >
                <span className="block text-xl font-bold">2</span>
                <span>Orders</span>
              </div>
              <div
                className={`text-center cursor-pointer p-4 rounded-lg ${
                  selectedSection === "addProduct"
                    ? "bg-white text-blue-600"
                    : ""
                }`}
                onClick={() => setSelectedSection("addProduct")}
              >
                <span className="block text-xl font-bold">3</span>
                <span>Add Product</span>
              </div>
              <div
                className={`text-center cursor-pointer p-4 rounded-lg ${
                  selectedSection === "products" ? "bg-white text-blue-600" : ""
                }`}
                onClick={() => setSelectedSection("products")}
              >
                <span className="block text-xl font-bold">4</span>
                <span>Products</span>
              </div>
            </div>
          </section>

          {/* Render Content Based on Selected Section */}
          <section className="bg-white shadow-md p-6 rounded-lg mb-6">
            {renderContent()}
          </section>
        </div>
      </div>
    </>
  );
};

export default SellerDashboard;
