import React from "react";
import PageOne from "../Pages/PageOne/PageOne";
import page2 from "../../assets/images/page2.png";
import Categories from "../Pages/Products/Categories";
import ForProducts from "../Products/ForYou/ForProducts";
import BottomBar from "../BottomBar/BottomBar";
import Navbar from "../Navbar/Navbar";

const HomePage = () => {
  return (
    <>
    <Navbar/>
      <PageOne />
      {/* <img src={page2}/> */}
      <Categories/>
      <ForProducts/>
      <BottomBar/>
    </>
  );
};

export default HomePage;
