import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import { useOrderContext } from "../Pages/Products/Oders/OrderContext";

const CheckOut = () => {
  const navigate = useNavigate();
  const { cart, setCart } = useShoppingCart(); // Get setCart from context
  const { userData } = useOrderContext();

  const [formData, setFormData] = useState({
    name: userData?.name || "",
    address: userData?.address || "",
    user: userData?.id || "",
    card_number: "", // Add this line
    orders: cart.map((item) => ({
      product_id: item.id,
      quantity: item.qty || 1,
      unit_price: item.price,
      total_price: (item.qty || 1) * item.price,
    })),
    total_price: cart.reduce(
      (acc, item) => acc + (item.qty || 1) * item.price,
      0
    ),
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      orders: cart.map((item) => ({
        product_id: item.id,
        quantity: item.qty || 1,
        unit_price: item.price,
        total_price: (item.qty || 1) * item.price,
      })),
      total_price: cart.reduce(
        (acc, item) => acc + (item.qty || 1) * item.price,
        0
      ),
    }));
  }, [cart, userData]);

  const handleQuantityChange = (index, qty) => {
    const updatedItems = formData.orders.map((item, i) =>
      i === index
        ? { ...item, quantity: qty, total_price: qty * item.unit_price }
        : item
    );
    const updatedTotal = updatedItems.reduce(
      (acc, item) => acc + item.total_price,
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      orders: updatedItems,
      total_price: updatedTotal,
    }));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Format the order data according to the API requirements
    const formattedOrders = formData.orders.map((order) => ({
      name: formData.name,
      ordered_item: order.quantity,
      card_number: formData.card_number, // Use formData.card_number here
      address: formData.address,
      paid_at: null,
      total_price: order.total_price.toFixed(2),
      delivered_at: null,
      order_status: "processing",
      user: formData.user,
      product: order.product_id,
    }));

    try {
      const response = await axios.post(
        "http://39.61.51.195:8004/account/create-order/",
        formattedOrders,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Order created successfully:", response.data);
      alert("Order created successfully");
      setCart([]); // Clear cart after successful order submission
      navigate("/order-success");
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        alert(
          `Error creating order: ${
            error.response.data.detail || "An error occurred"
          }`
        );
      } else if (error.request) {
        alert("Error creating order: No response received from the server.");
      } else {
        alert(`Error creating order: ${error.message}`);
      }
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-screen-lg mx-auto mt-8">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Checkout
      </h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
        {/* Recipient Information */}
        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Recipient Name:
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
              required
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              User ID:
            </label>
            <input
              type="number"
              name="user"
              value={formData.user}
              readOnly
              className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none cursor-not-allowed"
            />
          </div>
        </div>
        <div className="flex space-x-4">
        {/* Card Number */}
        <div className="flex-1">
          {" "}
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number:
          </label>
          <input
            type="text"
            name="card_number"
            value={formData.card_number}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
            required
          />
        </div>

        {/* Shipping Address */}
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Shipping Address:
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
            required
          />
        </div>
        </div>

        {/* Order Items Table */}
        <div className="border-t border-gray-200 pt-4">
          <h3 className="text-xl font-bold mb-4 text-gray-700">
            Order Details
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                    Product
                  </th>
                  <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                    Qty
                  </th>
                  <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                    Unit Price
                  </th>
                  <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {formData.orders.map((order, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4">
                      <div className="flex items-center">
                        <img
                          src={
                            cart[index]?.images.length > 0
                              ? cart[index].images[0].image
                              : "placeholder.jpg"
                          }
                          alt={cart[index]?.name}
                          className="w-12 h-12 object-contain rounded-lg mr-4"
                        />
                        <span>{cart[index]?.name}</span>
                      </div>
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={order.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, parseInt(e.target.value))
                        }
                        className="w-16 p-2 border border-gray-300 rounded-md focus:outline-none"
                        required
                      />
                    </td>
                    <td className="py-2 px-4">{order.unit_price}</td>
                    <td className="py-2 px-4">{order.total_price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300"
          >
            Submit Order
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckOut;
