import basket from "../../assets/images/ShoppingBasket1.png";
import lipstick from "../../assets/images/Lipstick1.png";
import cotton from "../../assets/images/CottonPoloShirt1.png";
import women from "../../assets/images/WomanClothes1.png";
import teddy from "../../assets/images/TeddyBear1.png";
import shopper from "../../assets/images/Shopper1.png";
import responsive from "../../assets/images/Responsive1.png";
import plug from "../../assets/images/Plug1.png";
import television from "../../assets/images/Television1.png";
import football from "../../assets/images/Football1.png";
import wrist from "../../assets/images/Wristwatch1.png";
import bike from "../../assets/images/Motorcycle1.png";

export const categories = [
  { name: "Groceries & Pets", img: basket },
  { name: "Health & Beauty", img: lipstick },
  { name: "Men's Fashion", img: cotton },
  { name: "Women's Fashion", img: women },
  { name: "Mother & Baby", img: teddy },
  { name: "Home & Lifestyle", img: shopper },
  { name: "Electronic Devices", img: responsive },
  { name: "Electronic Accessories", img: plug },
  { name: "TV & Home Appliances", img: television },
  { name: "Sports & Outdoor", img: football },
  { name: "Watches, Bags & Jewellery", img: wrist },
  { name: "Automotive & Motorbike", img: bike },
];
