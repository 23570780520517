import React, { createContext, useContext, useState, useEffect } from "react";

// Create a UserContext
const UserContext = createContext(null);

// Custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [googleLoginData, setGoogleLoginData] = useState(null);

  // Retrieve Google login data from localStorage when the component mounts
  useEffect(() => {
    const storedGoogleLoginData = localStorage.getItem("googleLoginData");
    if (storedGoogleLoginData) {
      setGoogleLoginData(JSON.parse(storedGoogleLoginData));
    }
  }, []);

  // Function to update the Google login data and store it in localStorage
  const updateGoogleLoginData = (data) => {
    setGoogleLoginData(data);
    localStorage.setItem("googleLoginData", JSON.stringify(data));
  };

  // Function to log out the user
  const logoutUser = () => {
    setGoogleLoginData(null);
    localStorage.removeItem("googleLoginData");
  };

  return (
    <UserContext.Provider value={{ googleLoginData, updateGoogleLoginData, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};