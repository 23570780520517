import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import HomePage from "./Components/HomePage/HomePage";
import About from "./Components/Pages/About/About";
import Privacy from "./Components/Pages/BottomBarPages/Privacy";
import Term from "./Components/Pages/BottomBarPages/Term";
import ContactUs from "./Components/Pages/BottomBarPages/ContactUs";
import UserLoginPage from "./Components/UsersAccounts/Accounts/UserLoginPage";
import UserSignupPage from "./Components/UsersAccounts/Accounts/UserSignupPage";
import SellerLoginPage from "./Components/SellerAccounts/Accounts/SellerLoginPage";
import ForgotPassword from "./Components/SellerAccounts/Accounts/ForgotPassword";
import SellerSignupPage from "./Components/SellerAccounts/Accounts/SellerSignupPage";
import SellerDashboard from "./Components/SellerAccounts/SellerDashboard/SellerDashboard";
import UserDashboard from "./Components/UsersAccounts/Dashboard/UserDashboard";
import AddProduct from "./Components/SellerAccounts/Products/AddProducts/AddProduct";
import { SellerProvider } from "./Components/SellerAccounts/Accounts/SellerContext";
import SearchResults from "./Components/Navbar/SearchResults";
import CreateCategory from "./Components/SellerAccounts/Products/CreateCategory/CreateCategory";
import Profile from "./Components/UsersAccounts/Profile/Profile";
import { ShoppingCartProvider } from "./Context/ShoppingCartContext";
import CreateOrderPage from "./Components/Pages/Products/Oders/CreateOrderPage";
import { OrderProvider } from "./Components/Pages/Products/Oders/OrderContext";
import Products from "./Components/Products/ForYou/Products";
import CheckOut from "./Components/Navbar/CheckOut";
import NotFound from "./NotFound";
import LoadingPage from "./LoadingPage";
import ScrollToTop from "./ScrollToTop";
import ProductDetails from "./Components/Navbar/ProductDetails";
import GoogleProfile from "./Components/UsersAccounts/Profile/GoogleProfile";
import { UserProvider } from "./Components/UsersAccounts/Accounts/UserContext";


const App = () => {
  return (
    <>
    <UserProvider>
      <OrderProvider>
        <Router>
        <ScrollToTop />
          <ShoppingCartProvider>
            <SellerProvider>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/search/:query" element={<SearchResults />} />
                  <Route path="/product/:id" component={<ProductDetails/>} /> 
                  <Route path="/about" element={<About />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/term" element={<Term />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/products" element={<Products />} />
                  {/* User Accounts URLS */}
                  <Route path="/userLogin" element={<UserLoginPage />} />
                  <Route path="/userReg" element={<UserSignupPage />} />
                  <Route path="/userdashboard" element={<UserDashboard />} />
                  <Route path="/createOrder" element={<CreateOrderPage />} />
                  <Route path="/checkout" element={<CheckOut />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/google-login-profile" element={<GoogleProfile />} />

                  {/* Loading Page */}
                  <Route
                    path="/loading"
                    element={<LoadingPage />}
                  />

                  {/* Seller Accounts URLS */}
                  <Route
                    path="/seller-login"
                    element={<SellerLoginPage />}
                  />
                  <Route path="/seller-signup" element={<SellerSignupPage />} />
                  <Route path="/ForgotPassword" element={<ForgotPassword />} />
                  <Route
                    path="/seller-dashboard"
                    element={<SellerDashboard />}
                  />
                  <Route path="/create-category" element={<CreateCategory />} />
                  <Route path="/add-products" element={<AddProduct />} />

                  {/* Catch all undefined routes */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
            </SellerProvider>
          </ShoppingCartProvider>
        </Router>
      </OrderProvider>
      </UserProvider>
    </>
  );
};


export default App;
