import React from "react";
import Navbar from "../../Navbar/Navbar";

const ContactUs = () => {
  return (
    <>
    <Navbar/>
    
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-blue-600 mb-4">Contact Us</h1>
        <p className="text-gray-600">Get in touch with our support team for assistance.</p>
      </div>
    </div>
    </>
  );
};

export default ContactUs;
