import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../../Components/Pages/Products/Oders/OrderContext";
import { useShoppingCart } from "../../../Context/ShoppingCartContext";

const ProductDetails = ({ product, onClose }) => {
  const { setProduct, userData } = useOrderContext();
  const { addToCart, notification } = useShoppingCart();
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(product?.images[0]?.image);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setProduct(product);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [product, setProduct]);

  const handleAddToCart = () => {
    if (userData) {
      addToCart(product);
    } else {
      navigate("/userLogin");
    }
  };

  const handleBuyNow = () => {
    if (userData) {
      navigate("/createOrder");
    } else {
      navigate("/userLogin");
    }
  };

  if (!product) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50 p-6">
      <div className="bg-white w-full max-w-4xl rounded-md shadow-lg overflow-hidden relative">
        
        {/* Fixed Header */}
        <div className="flex justify-between items-center p-4 border-b sticky top-0 bg-white z-10">
          {notification && (
            <div
              className={`absolute top-0 left-0 w-full p-2 text-center rounded-t-md ${
                notification === "Product added successfully!"
                  ? "bg-green-500"
                  : "bg-red-500"
              } text-white`}
            >
              {notification}
            </div>
          )}
          <h2 className="text-xl font-bold">{product.name}</h2>
          <button onClick={onClose} className="text-red-500 font-bold text-3xl">
            &times;
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-6 max-h-[calc(100vh-140px)] overflow-y-auto">
          <div className="flex flex-col items-center">
            {/* Main Image with Zoom on Hover */}
            <div className="w-full max-w-xs relative">
              <img
                src={currentImage}
                alt={product.name}
                className="w-full h-auto object-contain mb-4 rounded-lg shadow-lg transition-transform duration-300 hover:scale-110 hover:shadow-xl"
              />
            </div>
            
            {/* Thumbnail Images */}
            <div className="flex justify-center flex-wrap mt-4">
              {product.images.map((img, index) => (
                <div key={index} className="p-1">
                  <img
                    src={img.image}
                    alt={`View ${index + 1}`}
                    onClick={() => setCurrentImage(img.image)}
                    className="w-16 h-16 object-cover m-1 rounded-md cursor-pointer border border-gray-300 hover:border-blue-500 transition-transform duration-200 hover:scale-105"
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="text-gray-600 mb-4 text-sm md:text-base max-h-[120px] overflow-y-scroll">
              {product.description}
            </div>
            <p className="text-gray-800 font-bold text-xl md:text-xl">
              Price: {product.price}
            </p>
            <div className="border-t-2 border-b-2 py-4 my-4">
              <p className="text-gray-700 text-sm md:text-base">
                <strong>Delivery:</strong> Cash On Delivery
              </p>
            </div>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4">
              <button
                onClick={handleBuyNow}
                className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 hover:shadow-lg transition duration-300 transform hover:scale-105"
              >
                Buy Now
              </button>
              <button
                onClick={handleAddToCart}
                className="w-full py-3 bg-red-500 text-white rounded hover:bg-red-600 hover:shadow-lg transition duration-300 transform hover:scale-105"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
