import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useShoppingCart } from '../../Context/ShoppingCartContext';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { addToCart, notification } = useShoppingCart();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handleAddToCart = () => {
    addToCart(product);
  };

  const handleBuyNow = () => {
    // Implement Buy Now functionality, could be redirecting to checkout page
    alert('Redirecting to checkout...');
  };

  if (!product) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <img src={
                    product.images.length > 0
                      ? product.images[0].image
                      : "placeholder.jpg" // Fallback to a placeholder if the second image doesn't exist
                  } alt={product.name} className="w-full h-auto object-cover" />
        <div>
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-gray-600 mb-4">{product.description}</p>
          <p className="text-2xl font-bold mb-4">Rs. {product.price}</p>
          
          <div className="space-y-4">
            <button 
              onClick={handleBuyNow} 
              className="w-full py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200">
              Buy Now
            </button>
            <button 
              onClick={handleAddToCart} 
              className="w-full py-3 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
