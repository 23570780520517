import React, { useState, useRef, useEffect } from "react";
import { FaRegHeart } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import categoryIcon from "../../assets/images/catmanue.png";
import { Link } from "react-router-dom";
import { categories } from "./categoryData";
import CartDropdown from "./CartDropdown";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
 
const CategoryBar = () => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const categoryRef = useRef(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { cart, removeFromCart } = useShoppingCart();

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedIn === "true");
  }, []);

  const handleMouseEnterIcon = () => {
    setIsCategoryOpen(true);
  };

  const handleMouseLeaveIcon = () => {
    setTimeout(() => {
      if (categoryRef.current && !categoryRef.current.matches(":hover")) {
        setIsCategoryOpen(false);
        setHoveredCategory(null);
      }
    }, 200);
  };

  const handleMouseEnterCategory = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    setHoverPosition({ top: rect.top - 65, left: rect.right - 20 });
    setHoveredCategory(index);
  };

  const handleMouseLeaveCategory = () => {
    setHoveredCategory(null);
  };

  const handleCategoryClick = (index) => {
    const categoryName = categories[index].name;
    navigate(`/category/${categoryName.replace(/\s+/g, "-").toLowerCase()}`);
    setIsCategoryOpen(false);
  };

  return (
    <>
      <div className="text-white relative bg-gradient-to-r from-blue-400 to-blue-700 ">
        <div
          className="text-white relative "
          style={{
            background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
          }}
        >
          <nav className="p-2 flex items-center justify-between">
            <div
              className="flex items-center ml-7 cursor-pointer"
              onMouseEnter={handleMouseEnterIcon}
              onMouseLeave={handleMouseLeaveIcon}
            >
              <img src={categoryIcon} alt="Category Icon" className="mr-3" />
              <span className="text-lg">Categories</span>
            </div>
            <div className="flex items-center space-x-10 hidden sm:flex">
              <div className="flex items-center">
                <IoPersonOutline className="text-2xl mr-2" />
                <Link to={isLoggedIn ? "/profile"  : "/profile"}>
                  <span className="text-lg cursor-pointer">
                    {isLoggedIn ? "Profile" : "Profile"}
                  </span>
                </Link>
              </div>
              <div className="flex items-center">
                <FaRegHeart className="text-2xl mr-2" />
                <span className="text-lg cursor-pointer">Favorites</span>
              </div>
              <div className="flex items-center relative">
                <MdOutlineShoppingCart
                  className="text-2xl mr-2 cursor-pointer"
                  onClick={() => setIsCartOpen(!isCartOpen)}
                />
                {cart.length > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                    {cart.length}
                  </span>
                )}
                {isCartOpen && (
                  <CartDropdown
                    cart={cart}
                    onClose={() => setIsCartOpen(false)}
                    onRemove={removeFromCart}
                  />
                )}
              </div>
            </div>
          </nav>
          {isCategoryOpen && (
            <div
              className="absolute top-2 rounded-lg left-8 p-2 bg-blue-200 z-50"
              ref={categoryRef}
              onMouseLeave={handleMouseLeaveIcon}
            >
              <ul className="space-y-2 text-white">
                {categories.map((category, index) => (
                  <li
                    key={index}
                    className={`flex items-center cursor-pointer text-gray-700 hover:bg-blue-400 p-2 rounded-lg ${
                      hoveredCategory === index ? "bg-blue-300" : ""
                    }`}
                    onMouseEnter={(e) => handleMouseEnterCategory(index, e)}
                    onMouseLeave={handleMouseLeaveCategory}
                    onClick={() => handleCategoryClick(index)}
                  >
                    <div className="w-6 h-6 bg-gray-100 mr-3 flex items-center justify-center rounded-full">
                      <img
                        src={category.img}
                        className="w-4 h-4"
                        alt={category.name}
                      />
                    </div>
                    <span className="text-sm">{category.name}</span>
                  </li>
                ))}
              </ul>
              {/* {hoveredCategory !== null && (
                <div
                  className="absolute w-[15rem] bg-white text-black p-2 rounded-lg shadow-lg flex items-center hover:underline"
                  style={{ top: hoverPosition.top, left: hoverPosition.left }}
                >
                  <img
                    src={categories[hoveredCategory].img}
                    className="w-5 h-5 mr-2"
                    alt={categories[hoveredCategory].name}
                  />
                  <span>{categories[hoveredCategory].name}</span>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryBar;
