import React, { useState,  useEffect } from "react";
import { FaRegHeart, FaFacebookF } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { IoPersonOutline } from "react-icons/io5"; 
import { MdOutlineShoppingCart } from "react-icons/md";
import logo from "../../assets/images/Logo1.png";
import { Link } from "react-router-dom";
import Search from "./Search"; // Import the Search component
import CartDropdown from "./CartDropdown";
import { useShoppingCart } from "../../Context/ShoppingCartContext";
import CategoryBar from "./CategoryBar";


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { cart, removeFromCart } = useShoppingCart();
  

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedIn === "true");
  }, []);

  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="text-white relative bg-gradient-to-r from-blue-400 to-blue-700 sticky top-0 z-50">
        <div
          style={{
            background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
          }}
          className=" text-white relative"
        >
          <header className="flex items-center justify-between p-4 sm:hidden">
            <Link to={"/"}>
              <img className="h-12" src={logo} alt="Dehleez Logo" />
            </Link>
            {/* Burger Icon */}
            <div>
              <button
                onClick={toggleMenu}
                className="text-white hover:text-white-500 focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </header>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="sm:hidden bg-blue-500 ">
              <div className="flex flex-col items-center p-4">
                <div className="relative ml-5  mb-4">
                  <Search /> {/* Use Search component here */}
                </div>
                <nav className="flex flex-col items-center space-y-4">
                  <div className="flex items-center">
                    <IoPersonOutline className="text-2xl mr-2" />
                    <Link to={isLoggedIn ? "/profile"  : "/profile"}>
                  <span className="text-lg cursor-pointer">
                    {isLoggedIn ? "Profile" : "Profile"}
                  </span>
                </Link>

                  </div>
                  <div className="flex items-center">
                    <FaRegHeart className="text-2xl mr-2" />
                    <span className="text-lg cursor-pointer">Favorites</span>
                  </div>
                  
                  <div className="fixed items-center relative">
                  {/* <div className="fixed top-0 left-0 w-full bg-blue-500 z-50 sm:hidden"> */}
                    <MdOutlineShoppingCart
                      className="text-2xl mr-2 cursor-pointer"
                      onClick={() => setIsCartOpen(!isCartOpen)}
                    />
                    {cart.length > 0 && (
                      <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                        {cart.length}
                      </span>
                    )}
                    {isCartOpen && (
                      <CartDropdown
                        cart={cart}
                        onClose={() => setIsCartOpen(false)}
                        onRemove={removeFromCart}
                      />
                    )}
                  </div>
                </nav>
              </div>
            </div>
          )}
          {/* End Mobile Menu */}

          {/* Desktop Header */}
          <header className="hidden sm:flex items-center justify-between p-2">
            <Link to={"/"}>
              <img className="h-10 ml-7 " src={logo} alt="Dehleez Logo" />
            </Link>
            <div className="flex-grow mx-8">
              <div className="relative ml-20 mr-20 pr-20">
                <Search /> {/* Use Search component here */}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.instagram.com/dehleze9/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrInstagram className="text-white hover:text-gray-500 cursor-pointer text-2xl" />
              </a>
              <a
                href="https://www.facebook.com/dehleze9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="text-white hover:text-gray-500 cursor-pointer text-2xl" />
              </a>
            </div>
          </header>
          <CategoryBar/>
        </div>
      </div>
    </>
  );
};

export default Navbar;
