import React from 'react'

const ForgotPassword = () => {
  return (
    <>
      <h1>Forgot Password page</h1>
    </>
  )
}

export default ForgotPassword
