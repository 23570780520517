import React from "react";
import ForProducts from "./Components/Products/ForYou/ForProducts";

const NotFound = () => {
  return (
<>
     {/* <div style={{ textAlign: "center", marginTop: "50px" }}> */}
       {/* <h1>404 - Page Not Found</h1> */}
       {/* <p>We are currently working on this page!</p> */}
     {/* </div> */}
     <ForProducts/>
     </>
  );
};

export default NotFound;
