import React, { useState, useEffect } from "react";
import easypaisa from "../../assets/images/EasypaisaAppLogoVector1.png";
import jazcash from "../../assets/images/Dae7998E4Ddfb250B1646B3Bf6290C61.png";
import mastercard from "../../assets/images/Image113.png";
import visa from "../../assets/images/Image112.png";
import playstore from "../../assets/images/GooglePlayStoreBadgeEnSvg1.png";
import appstore from "../../assets/images/X1281.png";
import facebook from "../../assets/images/VecteezyFacebookLogoPngFacebookIconTransparentPng189306981.png";
import instagram from "../../assets/images/VecteezyInstagramLogoPngInstagramIconTransparent189304151.png";
import twitter from "../../assets/images/VecteezyNewTwitterXlogoTwitterIconXsocialMediaIcon421486111.png";
import youtube from "../../assets/images/VecteezyYoutubeLogoPngYoutubeIconTransparent189306881.png";
import bangladesh from "../../assets/images/Asset15.png";
import pakistan from "../../assets/images/pak.png";
import { Link } from "react-router-dom";
import LoadingPage from "../../LoadingPage";

const BottomBar = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <footer className="bg-gradient-to-r from-blue-500 to-blue-800 text-white py-10">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-4 gap-8 px-5">
          {/* Customer Care */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Customer Care</h3>
            <ul className="space-y-2">
             
              <li>
                <Link
                  to="/contact-us"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <a
                  href="/seller-login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Seller Account
                </a>
              </li>

              <li>
                <Link
                  to="/products"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Dehleze Shoping
                </Link>
              </li>
              
            </ul>
          </div>

          {/* Dehleze Info */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Dehleze</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/about"
                  className="hover:text-yellow-300 transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="term"
                  className="hover:text-yellow-300 transition-colors"
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Payment Methods */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Payment Methods</h3>
            <div className="flex flex-wrap gap-4 mb-5">
              <img
                src={easypaisa}
                alt="Easypaisa"
                className="w-[124px] hover:scale-105 transition-transform"
              />
              <img
                src={jazcash}
                alt="JazzCash"
                className="w-20 hover:scale-105 transition-transform"
              />
            </div>
          </div>

          {/* Exclusive Deals */}
          <div>
            <h3 className="text-2xl font-semibold mb-2">Follow Us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/dehleze9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="w-12 hover:scale-110 transition-transform"
                />
              </a>
              <a
                href="https://www.instagram.com/dehleze9/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="w-12 hover:scale-110 transition-transform"
                />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                {/* <img src={twitter} alt="Twitter" className="w-8 hover:scale-110 transition-transform" /> */}
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                {/* <img src={youtube} alt="YouTube" className="w-8 hover:scale-110 transition-transform" /> */}
              </a>
            </div>
          </div>
        </div>
        <div className="bg-bl900 mt-10 py-4">
          <div className="text-center text-white">
            © {new Date().getFullYear()} Dehleze. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
};

export default BottomBar;
