import React, { useContext, useEffect, useState } from "react";
import { SellerContext } from "../../Accounts/SellerContext";
import LoadingPage from "../../../../LoadingPage";

const ProductsList = () => {
  const { seller } = useContext(SellerContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      if (seller && seller.seller) {
        try {
          console.log("Fetching products for seller ID:", seller.seller.id);
          const response = await fetch(`http://39.61.51.195:8004/products`);
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const data = await response.json();
          console.log("Products fetched:", data);

          // Filter the products by seller ID
          const filteredProducts = data.filter(
            (product) => String(product.seller_id) === String(seller.seller.id)
          );
          
          setProducts(filteredProducts);
        } catch (error) {
          console.error("Error fetching products:", error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProducts();
  }, [seller]);

  const handleDeleteProduct = async (productId) => {
    const confirmed = window.confirm("Are you sure you want to delete this product?");
    if (!confirmed) return;
  
    // Log the token to verify it's correctly set
    console.log("Seller token:", seller.token);
  
    if (!seller.token) {
      alert("No valid token found. Please log in again.");
      return;
    }
  
    try {
      console.log(`Attempting to delete product with ID: ${productId}`);
      const response = await fetch(`http://39.61.51.195:8004/product-update/${productId}`, {
        method: "DELETE"
      });
  
      if (response.ok) {
        // Remove the product from the state
        setProducts(products.filter((product) => product.id !== productId));
        alert("Product deleted successfully!");
      } else {
        const errorData = await response.json();
        console.error("Failed to delete product:", errorData.message || "Authorization error");
        alert("Failed to delete product: " + (errorData.message || "Authorization error"));
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("An error occurred while deleting the product.");
    }
  };
  

  if (loading) {
    return <LoadingPage/>;
  }

  if (error) {
    return <p>Error fetching products: {error}</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="py-5">
        <h2 className="text-2xl font-bold mb-4">Products List:</h2>
        <p className="text-lg">Seller ID: {seller?.seller?.id || "N/A"}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-5">
        {products.length > 0 ? (
          products.map((product) => (
            <div
              className="bg-white rounded-lg shadow-md overflow-hidden"
              key={product.id}
            >
              <div className="flex justify-center items-center h-40">
                <img
                  src={
                    product.images.length > 0
                      ? product.images[0].image
                      : "placeholder.jpg" // Fallback to a placeholder if the second image doesn't exist
                  }
                  alt={product.name}
                  className="object-cover h-full w-full"
                />
              </div>
              <div className="p-4">
                <h2 className="text-xl text-gray-700 font-bold">{product.name}</h2>
                <p className="text-gray-700 mt-2 line-clamp-2">
                  {product.description}
                </p>
                <div className="flex justify-between items-center mt-4">
                  <p className="text-red-500 text-lg font-semibold">
                    Rs. {product.price}
                  </p>
                  <p className="text-green-500 text-sm">
                    Stock: {product.stock}
                  </p>
                  <button
                    onClick={() => handleDeleteProduct(product.id)}
                    className="p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No products found for this seller.</p>
        )}
      </div>
    </div>
  );
};

export default ProductsList;
