import React, { useState } from "react";

const OrderDetailModal = ({ order, onClose, statusOptions }) => {
  const [newStatus, setNewStatus] = useState(order.order_status);
  const [updating, setUpdating] = useState(false);

  const updateOrderStatus = async () => {
    try {
      setUpdating(true);
      const response = await fetch(`http://39.61.51.195:8004/account/orders/${order.id}/`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order_status: newStatus, name: order.name })
      });

      if (!response.ok) throw new Error(`Failed to update order. Status: ${response.status}`);
      const updatedOrder = await response.json();
      console.log("Order updated:", updatedOrder);

      alert("Order status updated successfully!");
      onClose();
    } catch (error) {
      console.error("Error updating order:", error);
      alert("Error updating order.");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Update Order Status</h2>
        <p><strong>Order Number:</strong> {order.id}</p>
        <select
          className="border rounded p-2 w-full"
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
        >
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="mt-4 flex justify-between">
          <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={updateOrderStatus}>
            {updating ? "Updating..." : "Update"}
          </button>
          <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailModal;
