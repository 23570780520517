import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value.length > 2) {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        const filteredSuggestions = response.data.filter((product) =>
          product.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    navigate(`/search/${suggestion.name}`);
    setQuery(suggestion.name);
    setSuggestions([]); // Clear the suggestions
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/${query}`);
    }
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        onKeyPress={handleKeyPress}
        className="bg-blue-500 text-white rounded-[10px] px-4 py-2 pl-10 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search"
      />
      <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white" />
      {suggestions.length > 0 && (
        <div className="absolute bg-white text-black w-full mt-2 rounded-md shadow-lg z-50">
          {suggestions.map((product) => (
            <div
              key={product.id}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleSuggestionClick(product)}
            >
              {product.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
