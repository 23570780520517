import React from 'react'
import Term from '../../../assets/About/Terms.png'



const page = () => {
  return (
    <>
      <div className=''>
        <img src={Term} alt="" className='mb-20 p-5' />
      </div>
    </>
  )
}

export default page