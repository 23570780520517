import React, { useState, useEffect } from "react";
import axios from "axios";
import { useOrderContext } from "./OrderContext"; // import the context
import { useNavigate } from "react-router-dom";

const OrderForm = () => {
  const navigate = useNavigate()
  const { userData, product } = useOrderContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    ordered_item: "1",
    address: "",
    total_price: "",
    order_status: "processing",
    user: "", // user id
    product: 1, // product id
    card_number: "", // for online payment (frontend only)
    paid_at: "", // for online payment (frontend only)
    payment_method: "cash_on_delivery", // default payment method (frontend only)
  });

  const [price, setPrice] = useState(0); // Separate state to store the product price

  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        name: userData.name,
        address: userData.address,
        user: userData.id,
      }));
    }
    if (product) {
      setFormData((prevData) => ({
        ...prevData,
        product: product.id,
      }));
      setPrice(product.price); // Set the price of the product
    }
  }, [userData, product]);

  useEffect(() => {
    // Calculate total price whenever ordered_item or price changes
    setFormData((prevData) => ({
      ...prevData,
      total_price: prevData.ordered_item * price,
    }));
  }, [formData.ordered_item, price]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.user) {
      alert("You need to log in first to place an order.");
      return; // Exit the function if the user is not logged in
    }

    setLoading(true); // Start loading animation

    // Prepare the data to be sent to the API, excluding unnecessary fields
    const dataToSubmit = {
      name: formData.name,
      ordered_item: formData.ordered_item,
      address: formData.address,
      total_price: formData.total_price,
      order_status: formData.order_status,
      user: formData.user,
      product: formData.product,
      card_number: formData.card_number,
    };

    try { 
      const response = await axios.post(
        "http://39.61.51.195:8004/account/create-order/",
        dataToSubmit
      );
      console.log("Order created successfully:", response.data);
      // alert("Order created successfully");
      navigate('/profile')
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Failed to create order. Please try again later.");
    } finally {
      setLoading(false); // Stop loading animation
    }
  };

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-lg max-w-screen-lg mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Create Order
      </h2>
      {!formData.user ? (
        <div className="bg-red-100 text-red-700 p-3 mb-4 rounded-md">
          <p>Please log in to place an order.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div className="md:col-span-1">
              {product ? (
                <img
                src={
                  product.images.length > 0
                    ? product.images[0].image
                    : "placeholder.jpg" // Fallback to a placeholder if the second image doesn't exist
                }                  alt={product.name}
                  className="w-full h-50 rounded-md shadow-md object-cover"
                />
              ) : (
                <p>Loading product image...</p>
              )}
            </div>
            <div className="md:col-span-2 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Produt Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={product.name}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none cursor-not-allowed"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Recipient Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Quantity:
                </label>
                <input
                  type="number"
                  name="ordered_item"
                  value={formData.ordered_item}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Enter quantity"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Unit Price:
                </label>
                <input
                  type="number"
                  name="price"
                  value={price}
                  readOnly
                  className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none cursor-not-allowed"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Total Price:
                </label>
                <input
                  type="number"
                  name="total_price"
                  value={formData.total_price}
                  readOnly
                  className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none cursor-not-allowed"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Payment Method:
                </label>
                <select
                  name="payment_method"
                  value={formData.payment_method}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                >
                  <option value="cash_on_delivery">Cash on Delivery</option>
                  <option value="online_payment">Online Payment</option>
                </select>
              </div>
            </div>
          </div>
            <div className="md:col-span-2 grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-semibold text-gray-600 mb-1">
                Shipping Address:
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                placeholder="Enter your address"
                required
              />
            </div>
            <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Phone Number:
                </label>
                <input
                  type="text"
                  name="card_number"
                  value={formData.card_number}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Enter reciever phone number"
                  required
                />
              </div>
          </div>
          {formData.payment_method === "online_payment" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Card Number (Showcase Only):
                </label>
                <input
                  type="text"
                  name="card_number"
                  value={formData.card_number}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                  placeholder="Enter your card number"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-600 mb-1">
                  Payment Date and Time (Showcase Only):
                </label>
                <input
                  type="datetime-local"
                  name="paid_at"
                  value={formData.paid_at}
                  onChange={handleChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-300"
                  required
                />
              </div>
            </div>
          )}

          <div className="flex justify-end ">
            <button
              type="submit"
              className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 mt-6 flex justify-center items-center"
              disabled={loading}
              style={{ minWidth: "120px" }} // Adjust this value to match the width of the "Submit Order" text
            >
              {loading ? (
                <div className="w-5 h-5 border-4 border-t-4 border-t-white border-blue-600 rounded-full animate-spin"></div>
              ) : (
                "Submit Order"
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default OrderForm;
