import React, { useContext, useEffect, useState } from "react";
import { SellerContext } from "../Accounts/SellerContext";
import LoadingPage from "../../../LoadingPage"; // Assume you have a loading page component
import OrderDetailModal from "./OrderDetailModal"; // Modal for updating order

const Orders = () => {
  const { seller } = useContext(SellerContext); // Seller context
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  
  const [statusOptions] = useState([
    { label: "Processing", value: "processing" },
    { label: "Pending", value: "pending" },
    { label: "Delivered", value: "delivered" },
    { label: "Cancelled", value: "cancelled" }
  ]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch orders
        const response = await fetch("http://39.61.51.195:8004/account/create-order/");
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();

        // Fetch seller's products
        const responseProducts = await fetch(`http://39.61.51.195:8004/products`);
        if (!responseProducts.ok) throw new Error(`HTTP error! status: ${responseProducts.status}`);
        const productsData = await responseProducts.json();

        // Filter orders related to seller's products
        const filteredProducts = productsData.filter(
          (product) => String(product.seller_id) === String(seller.seller.id)
        );
        const filteredOrders = data.filter((order) =>
          filteredProducts.some((product) => product.id === order.product)
        );

        setOrders(filteredOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (seller) fetchOrders();
  }, [seller]);

  const handleOrderClick = (order) => setSelectedOrder(order);
  const handleCloseModal = () => setSelectedOrder(null);

  if (loading) return <LoadingPage />;
  if (error) return <p>Error fetching orders: {error}</p>;

  return (
    <div className="h-screen flex flex-col overflow-y-auto">
      <div className="container mx-auto p-4 flex-grow">
        <h2 className="text-2xl font-bold mb-4">Order Management</h2>
        {orders.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {orders.map((order) => (
              <div
                className="bg-white p-4 rounded-lg shadow-md cursor-pointer transform hover:scale-105 transition-transform duration-300"
                key={order.id}
                onClick={() => handleOrderClick(order)}
              >
                <h3 className="text-xl font-bold">Order #: {order.id}</h3>
                <p><strong>User Name:</strong> {order.name}</p>
                <p><strong>Phone-#.:</strong> {order.card_number}</p>
                <p><strong>Address:</strong> {order.address}</p>
                <p><strong>Product ID:</strong> {order.product}</p>
                <p><strong>Order Status:</strong> {order.order_status}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>No orders found for the seller's products.</p>
        )}

        {/* Modal for updating order status */}
        {selectedOrder && (
          <OrderDetailModal
            order={selectedOrder}
            onClose={handleCloseModal}
            statusOptions={statusOptions}
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
