import { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode"; // Use directly if needed
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ecom from "../../../assets/New folder/Ecom.png";
import ecom1 from "../../../assets/New folder/Ecom1.png";
import Logo from "../../../assets/New folder/Logo.png";
import { Link } from "react-router-dom";
import "../../SellerAccounts/Accounts/Loading.css";
import BottomBar from "../../BottomBar/BottomBar";
import { MdRemoveRedEye } from "react-icons/md";
import { IoEyeOff } from "react-icons/io5";
import { useOrderContext } from "../../Pages/Products/Oders/OrderContext";
import Navbar from "../../Navbar/Navbar";

const UserLoginPage = () => {
  const { setGoogleUser } = useOrderContext(); // Destructure setGoogleUser from the context
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedEmail && savedPassword) {
      setFormData({
        email: savedEmail,
        password: savedPassword,
      });
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = formData;

    const requestData = {
      email: email,
      password: password,
    };

    try {
      const response = await fetch("http://39.61.51.195:8004/account/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      setLoading(false);
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        localStorage.setItem("isLoggedIn", "true");

        if (rememberMe) {
          localStorage.setItem("savedEmail", email);
          localStorage.setItem("savedPassword", password);
        } else {
          localStorage.removeItem("savedEmail");
          localStorage.removeItem("savedPassword");
        }

        const storedCart = localStorage.getItem("cart");
        if (storedCart) {
          localStorage.setItem("cart", JSON.stringify(JSON.parse(storedCart)));
        }

        navigate("/profile");
        window.location.reload();
      } else {
        console.error("Login failed");
        alert("Email Password does not match.");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;

    try {
      const response = await axios.post('http://39.61.51.195:8004/account/google-login/', { token });

      console.log('User data from backend:', response.data);

      setGoogleUser(response.data); // Set Google user data in context
      localStorage.setItem('user', JSON.stringify(response.data));
      localStorage.setItem('isLoggedIn', 'true');

      alert('Login Successful');
      navigate('/home');
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed. Please try again.');
    }
  };

  return (
    <>
    <Navbar/>
      <div className="flex flex-col lg:flex-row justify-center items-center mb-1">
        <div className="hidden lg:flex flex-1 bg-gradient-to-br from-blue-600 to-blue-400 text-white p-5 rounded-tr-2xl rounded-br-2xl flex-col">
          <div className="flex-1 flex flex-col justify-center">
            <img className="h-20 object-contain p-2 self-start" src={ecom1} alt="Ecom1" />
            <h5 className="text-2xl mt-4">Shop Smart, Save More – Welcome to Dehleze</h5>
            <img className="h-[350px] object-contain mt-4 self-center" src={ecom} alt="Ecom" />
          </div>
        </div>
        <div className="flex-1 lg:flex-1.5 w-full lg:w-auto h-auto pl-5 pr-5 flex flex-col justify-center">
          <img src={Logo} alt="" className="w-[350px] pl-5 pt-2 pb-5" />
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="E-mail"
              name="email"
              className="p-2 border border-gray-300 rounded-md mb-4"
              value={formData.email}
              onChange={handleChange}
            />
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                className="p-2 border border-gray-300 rounded-md mb-4 w-full"
                value={formData.password}
                onChange={handleChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-3 flex items-center justify-center text-gray-600"
                style={{ top: "40%", transform: "translateY(-50%)" }} // Centers the icon vertically
              >
                {showPassword ? <MdRemoveRedEye size={20} /> : <IoEyeOff size={20} />}
              </button>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              <label htmlFor="rememberMe">Remember Me</label>
            </div>
            <button
              type="submit"
              className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex justify-center items-center"
              disabled={loading}
            >
              {loading ? (
                <div className="w-5 h-5 border-4 border-t-4 border-t-white border-blue-600 rounded-full animate-spin"></div>
              ) : (
                "Login"
              )}
            </button>
          </form>
          {/* <GoogleLogin
            onSuccess={handleSuccess}
            onError={() => console.log("Login Failed")}
            text="signin_with"
          /> */}
          <p className="mt-4 text-center">
            Don't have an account?{" "}
            <Link to="/userReg" className="text-blue-600">Register</Link>
          </p>
          <p className="mt-4 text-center">
            By signing up you agree to the{" "}
            <a href="/terms" className="text-blue-600">Terms of Service</a> and{" "}
            <a href="/privacy" className="text-blue-600">Privacy Policy</a>
          </p>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default UserLoginPage;
