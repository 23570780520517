import React, { useState, useEffect } from "react";
import { FaHeart, FaStar } from "react-icons/fa"; // Import icons for favorites and star
import ProductDetails from "./CategoryProductsDetails"; // Ensure this path is correct

const Products = ({ categoryId }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [favoriteProducts, setFavoriteProducts] = useState({}); // Track favorite status

  useEffect(() => {
    fetch("http://39.61.51.195:8004/products/")
      .then((response) => response.json())
      .then((data) => {
        const filteredProducts = data.filter(
          (product) => product.category_Id === categoryId
        );
        setProducts(filteredProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, [categoryId]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  // Toggle favorite status
  const toggleFavorite = (productId) => {
    setFavoriteProducts((prevFavorites) => ({
      ...prevFavorites,
      [productId]: !prevFavorites[productId],
    }));
  };

  return (
    <div className="px-6 py-4">
      <h2 className="text-2xl sm:text-3xl font-semibold mb-6">Products</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-md rounded-lg p-4 cursor-pointer transform transition duration-200 hover:scale-105"
              onClick={() => handleProductClick(product)}
            >
              <img
                src={
                  product.images.length > 0
                    ? product.images[0].image
                    : "placeholder.jpg" // Fallback to a placeholder if no image exists
                }
                alt={product.name}
                className="w-full h-48 object-contain mb-4 cursor-pointer"

              />
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-bold text-base truncate w-3/4">
                  {product.name}
                </h3>
                <FaHeart
                  className={`cursor-pointer ${
                    favoriteProducts[product.id] ? "text-red-500" : "text-gray-300"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents triggering the parent click event
                    toggleFavorite(product.id);
                  }}
                />
              </div>
              <div className="text-left mb-2">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    className="text-yellow-400 inline-block"
                    size={14}
                  />
                ))}
              </div>
              <p className="text-gray-500 text-sm truncate w-full mb-2">
                {product.description}
              </p>
              <p className="font-bold text-lg text-left">
                Rs. {product.price}
              </p>
            </div>
          ))
        ) : (
          <p className="text-lg text-gray-600">No products available in this category.</p>
        )}
      </div>

      {selectedProduct && (
        <ProductDetails
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </div>
  );
};

export default Products;
