import React from 'react'
import pic1 from '../../../assets/About/Group 218.jpg'
import pic2 from '../../../assets/About/Group 198.jpg'
import pic3 from '../../../assets/About/Group 217.jpg'
import pic4 from '../../../assets/About/Group 196.jpg'
import pic5 from '../../../assets/About/Group 195.jpg'
import pic6 from '../../../assets/About/Group 194.jpg'
import pic7 from '../../../assets/About/Group 193.jpg'
import pic8 from '../../../assets/About/Group 191.jpg'
import pic9 from '../../../assets/About/Group 190.jpg'
import pic10 from '../../../assets/About/Group 189.jpg'


const About = () => {
  return (
    <>
      <div className=''>
        <img src={pic1} alt="" className='mb-20 p-5' />
        <img src={pic2} alt="" className='mb-20 p-5'/>
        <img src={pic3} alt="" className='mb-20 p-5'/>
        <img src={pic4} alt="" className='mb-20 p-5'/>
        <img src={pic5} alt="" className='mb-20 p-5'/>
        <img src={pic6} alt="" className='mb-20 p-5'/>
        <img src={pic7} alt="" className='mb-20 p-5'/>
        <img src={pic8} alt="" className='mb-20 p-5'/>
        <img src={pic9} alt="" className='mb-20 p-5'/>
        <img src={pic10} alt="" className='mb-20'/>
      </div>
    </>
  )
}

export default About
