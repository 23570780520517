import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import ProductDetails from "../DetailsPage/ProductDetails";
import LoadingPage from "../../../LoadingPage";
import Navbar from "../../Navbar/Navbar";
import BottomBar from "../../BottomBar/BottomBar";

const capitalizeFirstLetter = (string) => {
  return string.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [cart, setCart] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`http://39.61.51.195:8004/products/`);
        setProducts(response.data);
      } catch (error) {
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleLoadMore = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleFavoriteToggle = useCallback((id) => {
    setFavorites((prevFavorites) => ({
      ...prevFavorites,
      [id]: !prevFavorites[id],
    }));
  }, []);

  const handleAddToCart = useCallback((product) => {
    setCart((prevCart) => [...prevCart, product]);
  }, []);

  const handleProductClick = useCallback((product) => {
    setSelectedProduct(product);
  }, []);

  const handleClosePopup = useCallback(() => {
    setSelectedProduct(null);
  }, []);

  const displayedProducts = showAll ? products : products.slice(0, 13);

  if (loading) return <LoadingPage />;

  if (error) return <p>{error}</p>;

  return (
    <>
    <Navbar/>
      <div className="p-6">
        <section className="col-span-9">
          {displayedProducts.length > 0 ? (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
              {displayedProducts.map((product) => (
                <div
                  key={product.id}
                  className="bg-blue100 shadow-md rounded-lg cursor-pointer transform transition duration-200 hover:scale-105 overflow-hidden"
                >
                  <div className="w-full h-60 flex items-center justify-center">
                    <img
                      src={
                        product.images.length > 0
                          ? product.images[0].image
                          : "placeholder.jpg"
                      }
                      alt={product.name}
                      className="w-full h-full object-cover"
                      onClick={() => handleProductClick(product)}
                    />
                  </div>

                  <div className="flex justify-between items-center mb-1 px-4">
                    <h2 className="font-semibold text-gray-700 line-clamp-1">
                      {capitalizeFirstLetter(product.name)}
                    </h2>
                    <button
                      onClick={() => handleFavoriteToggle(product.id)}
                      className="text-red-500 hover:text-red-600"
                    >
                      {favorites[product.id] ? <FaHeart /> : <FaRegHeart />}
                    </button>
                  </div>
                  <div className="flex mb-2 px-4">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-4 h-4 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 15l-5.5 3 2.2-5.4L2 7.6l5.8-.5L10 2l2.2 5.1 5.8.5-4.7 4.6L15.5 18z" />
                      </svg>
                    ))}
                  </div>
                  <div className="flex items-center space-x-2 px-4">
                    <p className="text-gray-800 font-bold text-lg">
                      Rs. {product.price}
                    </p>
                    <p className="text-gray-500 line-through text-sm">
                      {(product.price * 1.05).toFixed(2)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No products found.</p>
          )}
          {!showAll && products.length > 8 && (
            <div className="flex justify-center mt-6">
              <button
                onClick={handleLoadMore}
                className="px-6 py-3 text-xl text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition-colors duration-300"
              >
                Load More
              </button>
            </div>
          )}
        </section>
      </div>

      {selectedProduct && (
        <ProductDetails product={selectedProduct} onClose={handleClosePopup} />
      )}
      <BottomBar/>
    </>
  );
};

export default Products;
