import React from "react";
import { TbTruckDelivery } from "react-icons/tb";
import { MdVerified, MdVerifiedUser } from "react-icons/md";
import ban1 from "../../../assets/SliderIMG/ban1.jpg";
import ban2 from "../../../assets/SliderIMG/ban2.jpg";
import ban3 from "../../../assets/SliderIMG/ban3.jpg";
import ban4 from "../../../assets/SliderIMG/ban4.jpg";
import assets310 from "../../../assets/images/Asset310.png";
import assets41 from "../../../assets/images/Asset41.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const PageOne = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Slider
        {...settings}
        className="slick-slider"
        style={{
          background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div>
          <Link to="/products">
            <img
              src={ban1}
              alt="Slide 1"
              className="w-full h-auto object-cover cursor-pointer"
              style={{ width: "100%", height: "auto", maxHeight: "400px" }}
            />
          </Link>
        </div>
        <Link to="/products">
          <div>
            <img
              src={ban2}
              alt="Slide 2"
              className="w-full h-auto object-cover cursor-pointer"
              style={{ width: "100%", height: "auto", maxHeight: "400px" }}
            />
          </div>
        </Link>
        <Link to="/products">
          <div>
            <img
              src={ban3}
              alt="Slide 3"
              className="w-full h-auto object-cover cursor-pointer"
              style={{ width: "100%", height: "auto", maxHeight: "400px" }}
            />
          </div>
        </Link>
        <Link to="/products">
          <div>
            <img
              src={ban4}
              alt="Slide 4"
              className="w-full h-auto object-cover cursor-pointer"
              style={{ width: "100%", height: "auto", maxHeight: "400px" }}
            />
          </div>
        </Link>
      </Slider>
      <div
        style={{
          background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
        }}
        className="text-white pl-4 sm:pl-6 pt-5 pb-5 w-full overflow-hidden"
      >
        <button className="bg-white text-blue-500 font-bold py-2 px-4 rounded-full hover:bg-blue-500 hover:text-white">
          <Link to={"/products"}>Shop Now</Link>
        </button>
      </div>

      <div
        style={{
          background: "linear-gradient(90deg, #60ACF8 0%, #0073E6 100%)",
        }}
        className="text-white relative w-full overflow-hidden"
      >
        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-4 p-4 bg-blue-100 text-blue-500 w-full">
          <div className="flex md:flex-row items-center mb-4 md:mb-0 md:flex-1 justify-center">
            <MdVerified className="text-2xl mr-2" />
            <span className="text-lg text-black">Verified Sellers</span>
          </div>
          <div className="flex md:flex-row items-center mb-4 md:mb-0 md:flex-1 justify-center">
            <img
              src={assets41}
              alt="Authentic Products"
              className="mr-2 w-6 h-6"
            />
            <span className="text-lg text-black">Authentic Products</span>
          </div>
          <div className="flex md:flex-row items-center mb-4 md:mb-0 md:flex-1 justify-center">
            <img src={assets310} alt="Free Returns" className="mr-2 w-6 h-6" />
            <span className="text-lg text-black">Free Returns</span>
          </div>
          <div className="flex md:flex-row items-center mb-4 md:mb-0 md:flex-1 justify-center">
            <TbTruckDelivery className="text-2xl mr-2" />
            <span className="text-lg text-black">Nationwide Delivery</span>
          </div>
          <div className="flex md:flex-row items-center mb-4 md:mb-0 md:flex-1 justify-center">
            <MdVerifiedUser className="text-2xl mr-2" />
            <span className="text-lg text-black">Safe Payments</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageOne;
