import React, { createContext, useState, useContext, useEffect } from "react";
import { useOrderContext } from "../Components/Pages/Products/Oders/OrderContext";
import { useNavigate } from "react-router-dom";

const ShoppingCartContext = createContext();

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};

export const ShoppingCartProvider = ({ children }) => {
  const { userData } = useOrderContext();
  const [cart, setCart] = useState([]);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      const storedCart = localStorage.getItem(`cart_${userData.id}`);
      setCart(storedCart ? JSON.parse(storedCart) : []);
    } else {
      setCart([]);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      localStorage.setItem(`cart_${userData.id}`, JSON.stringify(cart));
    }
  }, [cart, userData]);

  const addToCart = (product) => {
    if (!userData) {
      setNotification("Please log in to add products to your cart.");
      setTimeout(() => setNotification(null), 3000);
      navigate("/userLogin");
      return;
    }

    const productExists = cart.find((item) => item.id === product.id);

    if (productExists) {
      setNotification("Product already added to the cart!");
      setTimeout(() => setNotification(null), 3000);
    } else {
      setCart((prevCart) => [...prevCart, product]);
      setNotification("Product added successfully!");
      setTimeout(() => setNotification(null), 3000);
    }
  };

  const removeFromCart = (index) => {
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  return (
    <ShoppingCartContext.Provider
      value={{ cart, setCart, addToCart, removeFromCart, notification }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};
