import React, { useState, useEffect } from "react";
import Products from "./CategoryProducts";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    fetch("http://39.61.51.195:8004/account/category/")
      .then((response) => response.json())
      .then((data) => {
        const categoriesWithImages = data.map((category) => ({
          ...category,
          imageUrl: category.image || "default_image.jpg",
        }));
        setCategories(categoriesWithImages);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  return (
    <>
      <div className="text-center my-8">
        <h1 className="text-4xl font-bold text-gray-800">Categories</h1>
      </div>
      <div className="flex overflow-x-auto space-x-4 px-6 py-4">
        {categories.map((category) => (
          <div
            key={category.category_id}
            className={`bg-gradient-to-b from-blue-100 to-white shadow-lg rounded-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out p-4 min-w-[150px] box-border cursor-pointer ${
              selectedCategoryId === category.category_id ? "border-2 border-blue-500" : ""
            }`}
            onClick={() => handleCategoryClick(category.category_id)}
          >
            <img
              src={category.imageUrl}
              alt={category.name}
              className="w-32 h-32 object-cover mb-2 rounded-full border border-gray-300 shadow-sm"
            />
            <p className="text-center font-semibold text-lg text-gray-700">
              {category.name}
            </p>
          </div>
        ))}
      </div>
      {selectedCategoryId && (
        <div className="mt-10">
          <Products categoryId={selectedCategoryId} />
        </div>
      )}
    </>
  );
};

export default Categories;
